<template>
  <div class="page home">
    <div class="page-section header">
      <div class="gradation"></div>
      <div class="section-inner full">
        <!-- <div class="blur"></div> -->
        <video
          v-if="!isMobile()"
          src="https://player.vimeo.com/progressive_redirect/playback/1047742457/rendition/1080p/file.mp4?loc=external&log_user=0&signature=a4e3e4b1122bcb3bd0dbe22362fff64876c000c5612feb5a3df5390531f402b8"
          muted
          autoplay
          playsinline
        ></video>
        <video
          v-if="isMobile()"
          src="https://player.vimeo.com/progressive_redirect/playback/1047742446/rendition/1080p/file.mp4?loc=external&log_user=0&signature=5c3c1c9cce9cbd8072e9666d850e3a2dde3fddc15a9c557ab2cc0320b51d70bc"
          muted
          autoplay
          playsinline
        ></video>
      </div>
    </div>
    <div class="page-section fxl">
      <div class="gradation"></div>
      <div class="section-inner right">
        <h1 class="section-title">
          <img src="@/assets/images/home/thermage_flx.png" alt="" />
          <sup
            style="
              font-size: 16px;
              vertical-align: super;
              position: relative;
              top: -11px;
            "
            >1</sup
          >
        </h1>
        <div class="inner-text">
          Thermage FLX는 솔타메디칼에서 개발된 범용전기수술기로
          <b
            >고주파 전류를 이용하여 조직의 응고로 눈가 및 안면상 주름의 비침습적
            치료에 사용하는 기구</b
          >입니다.<br />
          <br />
          Thermage FLX는 편안해진 에너지 전달과 <br />
          진동기능을 탑재한 핸드피스와 <br />
          다양한 종류의 팁으로 시술합니다.<br />
        </div>
        <div class="inner-btn">
          <v-btn
            x-large
            outlined
            color="primary"
            @click="$router.push('/brand')"
            >제품정보 보러가기</v-btn
          >
        </div>

        <!-- @@ sh 섹션 하단 여백 추가 방식 수정 (vertical-space 삭제) -->
        <!-- <div class="references">
          <b>Reference</b><br />
          1. 식품의약품안전처 허가증(2018) Thermage FLX System. 수허 18-245.<br />
          <p class="part-number mt-6">KTMG-20240122-042</p>
        </div> -->
      </div>
    </div>
    <div class="page-section">
      <div class="section-inner full">
        <!-- <div class="blur"></div> -->
        <video
          src="https://player.vimeo.com/progressive_redirect/playback/1047742465/rendition/1080p/file.mp4?loc=external&log_user=0&signature=eda90f8358d6418de4b9954a8ef5ae255d15b753250cda7210f4c6c656568218"
          muted
          autoplay
          playsinline
        ></video>
      </div>
    </div>

    <!-- @@jy FLX 레퍼런스 하단이동. class 확인필요 -->
    <div class="reference">
      <div class="section-inner">
        <b>Reference</b><br />
        1. 식품의약품안전처 허가증 Thermage FLX System 수허 18-245 호_Cover<br />
        •Thermage FLX™ 제품은 "의료기기" 이며, "사용상의 주의사항"과 "사용방법"을 잘 읽고 사용하십시오.<br/>
        •심의확인번호: 조합2024-22-070 (유효기간 2027.06.10)
        <p class="part-number mt-6">KTMG-20231012-027, KTMG-20240620-088</p>
      </div>
    </div>

    <!-- 임시 공지 팝업 원복 시 주석 해제 해야할 컴포넌트 :PopupDialog -->
    <PopupDialog ref="PopupDialog" />

    <!-- <TempNoticeDialog ref="TempNoticeDialog" /> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import PopupDialog from "@/components/common/dialog/PopupDialog.vue";
// import TempNoticeDialog from "@/components/common/dialog/TempNoticeDialog.vue";
import cookie from "js-cookie";

export default {
  name: "HomePage",
  components: { PopupDialog },
  data() {
    return {
      reqData: {
        endpoint: "bbs/targetPopup",
      },
    };
  },
  mounted() {
    // 오늘 안보기 선택시
    if (cookie.get("homePopup")) return;

    // 기존 팝업 오픈 함수 호출. 원복 시 주석 해제
    this.getPopupInfo();

    // 임시 팝업, 원복 시 제거 필요
    // this.$refs.TempNoticeDialog.open();
  },
  methods: {
    isMobile() {
      return window.matchMedia("(pointer:coarse)").matches;
    },
    getPopupInfo() {
      this.getDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        console.log("res", res);
        if (!res) return;
        this.$refs.PopupDialog.open(res.data);
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>

<style lang="scss">
@media (max-width: 768px) {
  div .home {
    .page-section {
      height: auto;
      &.header {
        height: calc(100vh - 60px);
        margin-top: 60px;
      }
      .gradation {
        display: none;
      }
      // video {
      //   position: relative;
      //   object-fit: contain;
      // }
      .section-inner.full {
        background-image: url(~@/assets/images/home/video_bg.png);
        background-position: center;
        background-size: cover;
        height: 100%;

        .blur {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(5px);
          background-color: rgba(141, 141, 141, 0.1);
        }
      }
    }

    .page-section.fxl {
      background-image: url(~@/assets/images/home/mobile_home_thermage_bg_3.png);
      background-position: center;
      background-size: cover;
      height: calc(100vh - 60px);
      display: block;
      // padding: 40px 0 80px;
      .section-inner {
        padding-bottom: 20vh;
        text-align: center;
        .section-title {
          margin: 40px 0 30px;
          line-height: 0;
          text-align: center;
          img {
            width: 200px;
          }
        }
        .inner-text {
          padding-left: 0;
          margin: 1rem 0 9rem;
          font-size: 14px;
          color: $primary-color;
          font-weight: 600;
          b {
            color: $point-color;
            font-weight: 900;
          }
        }
        .inner-btn {
          .v-btn {
            height: 42px !important;
            font-size: 14px;
            border-width: 2px;
          }
        }
        .references {
          font-size: 12px;
          margin-top: 2rem;
        }
      }
    }
  }
}
.home {
  padding-bottom: 0;
  .page-section {
    height: calc(100vh - 70px);
    .gradation {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10vh;
      background: linear-gradient(#ffffff, transparent);
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .page-section.fxl {
    display: flex;
    align-items: center;
    background-image: url(~@/assets/images/home/home_thermage_bg2.png);
    background-position: left center;
    background-size: cover;
    .gradation {
      width: 100%;
      height: 100%;
      opacity: 0.9;
      background: linear-gradient(90deg, transparent 20%, #ffffff);
    }
    .section-inner {
      position: relative;
      .section-title {
        font-size: 4rem;
        margin: 20px 0;
        img {
          width: 366px;
        }
      }
      .inner-text {
        padding-left: 60%;
        margin: 2rem 0 4rem;
      }
      .references {
        font-size: 14px;
        margin-top: 4rem;
        color: $secondary-color;
      }
    }
  }
}
</style>
