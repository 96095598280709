<template>
  <div class="page submitted">
    <div class="page-section">
      <div class="section-inner">
        <div class="section-title">
          <img
            class="top-logo"
            src="@/assets/images/common/thermage_logo.png"
            alt="thermage_logo"
          />
          <p class="sub-title">
            써마지<sup>®</sup> 정품팁을 사용해주셔서 감사합니다.
          </p>
          <h1 class="main-title primary--text">
            기프티콘은 영업일 기준 3~7일 이후 받아 보실 수 있습니다.
          </h1>
        </div>

        <div class="submit-btn-wrap">
          <v-btn
            depressed
            color="primary"
            x-large
            block
            @click="$router.push('/')"
            >홈 화면으로 돌아가기</v-btn
          >
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss">
.submitted {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    .submit-btn-wrap {
      margin-top: 40px;
    }
    .section-inner .section-title {
      .main-title {
        font-size: 34px;
      }
    }
    @media (max-width: 768px) {
      div.section-inner .section-title {
        .main-title {
          font-size: 18px;
        }
      }
    }
  }
}
</style>
